<ng-template #loginForm>
  <div id="basic-authentication" class="usr-screen ciam-landing">
    <form #userForm="ngForm" (ngSubmit)="onSubmit(userForm)">
      <div class="clm-12">
        <div class="form-item material stable full-w">
          <label for="username">Username:</label>
          <input
            [ngClass]="{'input-error': isInputError}"
            type="text"
            id="username"
            name="username"
            [(ngModel)]="username"
            required
          />
        </div>
      </div>
      <div class="clm-12">
        <div class="form-item material stable full-w">
          <label for="password">Password:</label>
          <input
            [ngClass]="{'input-error': isInputError}"
            type="password"
            id="password"
            name="password"
            [(ngModel)]="password"
            required
          />
        </div>
      </div>

      <div class="button-region right">
        <div style="margin-top: 2em;">
          <button
            class="enabled"
            type="submit"
            class="primary"
            [disabled]="userForm.invalid"
            [ngClass]="userForm.invalid ? 'btn-disabled' : ''"
          >
            <span>Login</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<app-common-modal
  [isHeaderVisible]="true"
  [isFooterVisible]="true"
  component="create-edit-alias"
  [showModalContent]="loginForm"
  [type]="popupType"
></app-common-modal>

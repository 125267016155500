import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { ErrorHandlingInterceptor } from './interceptors/error-handling.interceptor';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { AwsAuthService } from './services/aws-auth.service';
import { LogoutHandlerComponent } from './components/logout-handler/logout-handler.component';
import { CommonModule } from '@angular/common';
import { TranslationService } from './services/translation.service';
import { firstValueFrom, tap } from 'rxjs';
import { SharedModule } from './share.module';
import { LanguageSelectionPageComponent } from './components/language-selection-page/language-selection-page.component';
import { CommonErrorDisplayComponent } from './components/common-error-display/common-error-display.component';
import { JwtInterceptor } from './modules/stories/_helper/jwt.interceptor';

export function loadFeatureFlagsFactory(translationService: TranslationService): () => Promise<any> {
  return () => firstValueFrom(translationService.getAllFeatureFlags().pipe(
      tap((featureFlags: any) => {
          translationService.featureFlags = featureFlags;
      })
  ));
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundPageComponent,
    MainPageComponent,
    ComingSoonComponent,
    LogoutHandlerComponent,
    LanguageSelectionPageComponent,
    CommonErrorDisplayComponent,
  ],
  imports: [
    BrowserModule,
    HammerModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
              AwsAuthService,
              {
                provide: APP_INITIALIZER,
                useFactory: loadFeatureFlagsFactory,
                deps: [TranslationService],
                multi: true
            },
              {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true}],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicAuth } from '../interfaces/basic-auth.interface';

@Injectable({
  providedIn: 'root'
})
export class GenericLoginService {

  private genericLoginSubject = new BehaviorSubject<boolean | null>(null);
  constructor(private httpClient: HttpClient) { 
    const isAuthenticated = localStorage.getItem('isBasicAuthSuccess');
    if (isAuthenticated === 'true') {
      this.genericLoginSubject.next(true);
    }
  }

  getGenericLoginObservable(): Observable<boolean | null> {
    return this.genericLoginSubject.asObservable();
  }

  openGenericLoginModal(): Promise<boolean> {
    this.genericLoginSubject.next(false);

    return new Promise<boolean>((resolve) => {
      this.genericLoginSubject.subscribe((result) => {
        if (result !== null) {
          resolve(result);
        }
      });
    });
  }

  triggerLogin() {
    localStorage.setItem('isBasicAuthSuccess', 'true'); 
    this.genericLoginSubject.next(true); 
  }

  declineLogin() {
    this.genericLoginSubject.next(false);
  }

  /**
   * @description Temporary login for dev and int portals
   * @param username 
   * @param password 
   * @returns Observable
   */
  public basicAuthLogin(username: string, password: string): Observable<BasicAuth> {
    const url = environment.proxyEndpoint + '/auth/checkUserAccess'
    return this.httpClient.post<BasicAuth>(url,
      {"userName": username,"userPassword" :password}
    );
  }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toastDetails: any = new Subject<any>();
  ToastType: any;
  show(toastType: any, message: any, position:any, leftPosition:any) {
    let data = {
      isShown: true,
      message: message,
      toastType: toastType,
      position: position,
      leftPosition: leftPosition
    }
    this.toastDetails.next(data);
  }
  hide() {
    let data = {
      isShown: false,
      message: '',
      toastType: null
    }
    this.toastDetails.next(data);
  }
}

export enum ToastType {
  Success,
  Error,
  Notification
}

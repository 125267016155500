import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppServicesService } from 'src/app/services/app-services.service';
import { LanguageService } from 'src/app/services/language.service';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  translationData = [];
  isFooterAvailable : boolean = false;
  featureFlags: any= this.translationService.featureFlags;
  isExplorePage: boolean = false;
  currentLanguage = 'de-DE';
  language: string = localStorage.getItem('language') || 'de-DE';
  isLanguageSelector: boolean = false;
  languageToDisplay: string = 'Deutsch';

  @Output() scrollToTop = new EventEmitter();
  showLangPicker: boolean = false;

  constructor(private translationService: TranslationService,
    private router: Router, 
    private _appService: AppServicesService, 
    private languageService: LanguageService,
  ) {
    let isExploreScreen = false;
    this.router?.events?.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        isExploreScreen = event.url.includes('explore');
        this.isExplorePage = this.featureFlags?.general.enableExplore && isExploreScreen;
      }
    });
    this.featureFlags = this.translationService.featureFlags;
    this.isFooterAvailable = this.featureFlags?.general.isfooterAvailable;
    this.language = this.languageService.getLanguage();
    this.getUserSelectedLanguage(this.language);
  }

  getUserLanguage(): void {
    let data = localStorage.getItem('language') || 'de-DE';
    if (data === 'en-GB') {
      this.currentLanguage = 'Deutsch';
    } else {
    const supportedLocales: any = {
          'de-DE': 'Deutsch',
          'ko-KR': 'Korean',
          'fr-FR': 'French',
          'es-ES': 'Spanish',
        }
        data = supportedLocales[data] ? data : 'de-DE';
        this.currentLanguage = supportedLocales[data];
      }
  }

  ngOnInit() {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
        this.language = this.languageService.getLanguage();
        this.getUserSelectedLanguage(this.language);
      }
    )

    if(this.featureFlags?.general.enableLanguageSelector){
      this.isLanguageSelector = true;
    }

  }

  public openPrivacyPage(): void {
    this.scrollTop();
    const selectedLang = localStorage.getItem('language') ?? 'en-GB';
    this.router.navigate(['/' + selectedLang + '/privacy']);
  }

  public openProviderPage(): void {
    this.scrollTop();
    const selectedLang = localStorage.getItem('language') ?? 'en-GB';
    this.router.navigate(['/' + selectedLang + '/provider']);
  }

  public openLegalPage(): void {
    this.scrollTop();
    const selectedLang = localStorage.getItem('language') ?? 'en-GB';
    this.router.navigate(['/' + selectedLang + '/legal-notice']);
  }

  public switchLanguage(language:string) {
    const supportedLocales: any = {
      'English': 'en-GB',
      'Deutsch': 'de-DE',
      'Korean': 'ko-KR',
      'French': 'fr-FR',
      'Spanish': 'es-ES',
      'Czech': 'cs-CZ'
    }
    const selectedLang = (url: string) => supportedLocales[url] ? supportedLocales[url] : 'de-DE';
    const fullURL = window.location.href.split('/');
    const langIndex = fullURL.findIndex(part => this.languageService.checkIsValideLanguage(part));
    fullURL[langIndex] = selectedLang(language);
    localStorage.setItem('language', selectedLang(language));
    window.location.href = fullURL.join('/');
    this.currentLanguage = language;
  }

  public getUserSelectedLanguage(participantLang: string) {
    let urlLang = participantLang;
    const supportedLocales: any = {
      'en-GB': 'English',
      'de-DE': 'Deutsch',
      'de': 'Deutsch',
      'ko-KR': 'Korean',
      'ko': 'Korean',
      'fr-FR': 'French',
      'fr': 'French',
      'es-ES': 'Spanish',
      'es': 'Spanish',
      'en': 'English',
      'en-US': 'English',
      'cs-CZ': 'Czech',
    }
    const fullURL = window.location.href.split('/');
    const langIndex = fullURL.findIndex(part => this.languageService.checkIsValideLanguage(part));
    let isEngilsh = (lang: string) => lang && lang.includes('en') ? true : false;
    if (langIndex !== -1) {
      urlLang = fullURL[langIndex];
    }
    this.languageToDisplay = supportedLocales[urlLang] ?? 'English';
    const locale = isEngilsh(urlLang) ? participantLang : urlLang;
    const selectedLang = (url: string) => supportedLocales[url];
    this.currentLanguage = !isEngilsh(locale) ? selectedLang(locale) : selectedLang(isEngilsh(this.language) ? 'de-DE' : this.language);
  }

  public scrollTop(): void {
    // this.scrollToTop.emit('#target');
    window.scroll({
      top:0,
      left:0,
      behavior:'smooth'
    })
  }
  
  openLanguagePicker(){
    this.languageService.showLanguageSelectionPage(true);
  }

}

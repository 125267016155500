import { Injectable } from '@angular/core';
import {  CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericLoginService } from './generic-login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BasicAuhtenticationGuard implements CanActivate { 

  constructor(private genericLoginService: GenericLoginService) {}

  canActivate(): Promise<boolean> | boolean {
    const user = localStorage.getItem('isBasicAuthSuccess');

    if (user === 'true' || environment.production) { // if it is a production environment then always allow without a check for basic auth
      return true;
    } else {
      this.genericLoginService.openGenericLoginModal();
      return true;
    }
  }
  
}

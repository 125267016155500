<!--I have removed all conditions from here because all Coming-soon scenarions are
  handleled by routes and guards-->
<div #scrollElement></div>

<header id="main-header" [ngClass]="{'no-animation': noAnimation }"><app-header></app-header></header>
<main id="main-content">
  <router-outlet></router-outlet>
</main>
<ng-container *ngIf="showLoginForNonProdEnviorment">
  <app-generic-login-page></app-generic-login-page>
</ng-container>
<div *ngIf="showToast">
    <app-toast [message]="toastMessage" [toastType]="toastType" [position]="position" [leftPosition]="leftPosition">
    </app-toast>
</div>
<!-- <app-refresh-token></app-refresh-token> -->
<footer>
  <app-footer (scrollToTop)="scrollTop($event)"></app-footer>
</footer>

<div class="language-picker">
  <ng-container *ngIf="showLangPicker">
      <app-language-selection-page ></app-language-selection-page>
  </ng-container>
  </div>


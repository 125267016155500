import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
    constructor() {
        console.log('JwtInterceptor')
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('JwtInterceptor triggered');
        //add auth header with jwt if user is logged in and request is to the api url
        // const currentUser = this.authenticationService.currentUserValue;
        // const isLoggedIn = currentUser && currentUser.access_token;
        // const storiesUrlList = ['/aes', '/api/v2', '/aus']
        let currentUser = null;
        let isLoggedIn = false;
        if(currentUser) {
            const currentUserData = JSON.parse(currentUser);
            isLoggedIn = currentUser && currentUserData.access_token;
            const isApiUrl = false;
            if (isLoggedIn && isApiUrl) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUserData.access_token}`
                    }
                });
            }
        }
        if(request.url.startsWith(environment.newraceUrl+'web')){
            request = request.clone({
                setHeaders: {
                    username:'mestoriesuser',
                    password:'mestoriesuser@1234'
                }
            });
        }
        return next.handle(request);
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, delay, forkJoin, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  public translatedArray: any = [];
  public languageType: string = '';
  public translationContent = new BehaviorSubject<any>(this.translatedArray);
  public sharetranslationData = this.translationContent.asObservable();
  public languageContent = new BehaviorSubject<any>(this.languageType);
  public sharelanguageType = this.languageContent.asObservable();
  private _featureFlags: any = null;
  private _featureFlagLink = environment.featureFlagLink
  private _supportedLanguagesForDisplay: any[] = [];
  supportedLanguages: any;
  
  constructor(private httpClient: HttpClient) { }
  sendtranslationValue(value: any) {
    this.translationContent.next(value);
  }
  sendSelectedLanguage(value: any) {
    this.languageContent.next(value);
  }

  setTranslationData(data: any) {
    this.translatedArray = data;
  }
  getTranslationData() {
    return this.translatedArray;
  }

  getTranslationDataAsObservable() : Observable<boolean> {    
      return this.translationContent.asObservable();
  }

  setLanguageType(type: any) {
    this.languageType = type;
  }
  getLanguageType() {
    return this.languageType;
  }


  getAllLanguages() {
    //https://api.phrase.com/v2/projects/44e8b7de2c6a909f3afeafc8993b3bb3/locales
    return this.httpClient.get(`https://api.phrase.com/v2/projects/5704f8ba49ae1e6a8ac365c64b2650de/locales?access_token=a2a6d17811db4560969c2e8d6d261037a5afaae66d219f220110c293372c5b3b`);
  }


  phraseApiCallForTranslations(languageId: string): Observable<any> {
    let endPoint = environment.phraseUrl;
    if (languageId === 'de') {
      languageId = 'de-DE';
    } else if (languageId === 'en') {
      languageId = 'en-GB';
    }
    endPoint = endPoint.replace('@languageId', languageId);
    let page1 = this.httpClient.get(`${endPoint}&page=1&per_page=100`);
    let page2 = this.httpClient.get(`${endPoint}&page=2&per_page=100`);
    let page3 = this.httpClient.get(`${endPoint}&page=3&per_page=100`);
    return forkJoin([page1, page2, page3]);
  }

  public set featureFlags(flags: any) {
    this._featureFlags = flags;
  }

  public get featureFlags() {
    return this._featureFlags;
  }

  public getAllFeatureFlags(): Observable<any> {
    let featureFlags = this.httpClient.get(this._featureFlagLink)
    return featureFlags  
  }

     /**
   * @description To be replaced by actual API. Gives list of all supported languages
   * @returns list of languages
   * !IMPORTANT: While testing with actual API, ensure error scenarios are also handled
   */
     public getAllSupportedLanguages(): Observable<any> {
      const res = require('../../app/components/language-selection-page/language-list.json');
      return of(res).pipe(delay(100));
    }
  
    public set supportedLanguagesForDisplay(languages: any[]) {
      const defaultLangs = ['en', 'de'];
      const langCodes = languages.map(lang => lang.localeCode);
      langCodes.push(...defaultLangs);
      this.setSupportedLanguageByCode(langCodes);
      this._supportedLanguagesForDisplay = languages;
    }
  
    public get supportedLanguagesForDisplay(): any[] {
      return this._supportedLanguagesForDisplay;
    }
  
    public setSupportedLanguageByCode(languages: string[]) {
      this.supportedLanguages = languages;
    }


}


import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppServicesService } from 'src/app/services/app-services.service';
import { LanguageService } from 'src/app/services/language.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-language-selection-page',
  templateUrl: './language-selection-page.component.html',
  styleUrls: ['./language-selection-page.component.scss']
})
export class LanguageSelectionPageComponent implements OnInit {

  @Output() public islanguagePageVisible = new EventEmitter<any>();
  @Output() public sendLangId = new EventEmitter<any>();
  allLanguages: any[] = [];
  translationData = [];
  raceId:any = '';
  constructor(private translationService: TranslationService, 
    private _languageService: LanguageService) { }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    );
    this.allLanguages = this.translationService.supportedLanguagesForDisplay;
  }

  public closeLanguagePicker(){
    this._languageService.showLanguageSelectionPage(false);
  }

  public emitLangId(id:any){
    this.sendLangId.emit(id);
    this.islanguagePageVisible.emit(false);
  }

  public switchLanguage(language:string) {
    this.raceId = localStorage.getItem('raceId') 
    if(this.raceId){
      this.addRaceIdWithLanguage()
    }
    localStorage.setItem('language', language);
    sessionStorage.setItem('languagePicked', 'true')
    this.appendLanguageIdToUrl(language);
  }

  public appendLanguageIdToUrl(language: string) {
    const fullURL = window.location.href.split('/');
    fullURL[3] = language;
    window.location.href = fullURL.join('/');
  }

  public addRaceIdWithLanguage(){
    let raceLang = {
      'languagePicked': true,
      'raceid': this.raceId
    }
    sessionStorage.setItem('languagePicked',JSON.stringify(raceLang))
  }
}

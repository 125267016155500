<ng-container *ngIf="isTranslationDone && translationData.length">
<ng-container *ngIf="content | customTranslation: translationData; ">

    <section class="legal-page-container" [innerHTML]="content | customTranslation: translationData">
    
    </section>
    </ng-container>
    <ng-container *ngIf="isNotFound">
        <app-no-route></app-no-route>
    </ng-container>
</ng-container>
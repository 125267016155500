import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NoRouteComponent } from './modules/shared/components/no-route/no-route.component';
import { CiamLoginComponent } from './components/ciam-login/ciam-login.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { ToggleGuard } from './services/toggle-guard.guard';
import { RouterLanguageGaurdGuard } from './services/router-language-gaurd.guard';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { LogoutHandlerComponent } from './components/logout-handler/logout-handler.component';
import { ProdEnabledGuard } from './services/prod-enabled.guard';
import { LegalPageComponent } from './modules/shared/pages/legal-page/legal-page.component';
import { ProviderPageComponent } from './modules/shared/pages/provider-page/provider-page.component';
import { PrivacyPageComponent } from './modules/shared/pages/privacy-page/privacy-page.component';
import { BasicAuhtenticationGuard } from './services/basic-auhtentication.guard';
export const routes: Routes = [
  {
    path: ':languageId/home',
    component: MainPageComponent,
    canActivate: [BasicAuhtenticationGuard]
  },
  {
    path: 'login',
    component: CiamLoginComponent,
    canActivate: [BasicAuhtenticationGuard]
  },  
  {
    path: 'anonymize/:partipationInfo', 
    children: [
      {
        path: '**',
        children: [],
        canActivate: [BasicAuhtenticationGuard, RouterLanguageGaurdGuard]
      }
    ],
    canActivate: [BasicAuhtenticationGuard, RouterLanguageGaurdGuard]
  },
  {
    path: ':languageId/anonymize/:partipationInfo',
    children: [
      {
        path: '**',
        children: [],
        canActivate: [BasicAuhtenticationGuard, RouterLanguageGaurdGuard]
      }
    ],
    canActivate: [BasicAuhtenticationGuard, RouterLanguageGaurdGuard]
  },
  {
    path: 'logout',
    component: LogoutHandlerComponent,
  },

   
  {
    path: 'verify/:partipationInfo', 
    children: [
      {
        path: '**',
        children: [],
        canActivate: [BasicAuhtenticationGuard, RouterLanguageGaurdGuard]
      }
    ],
    canActivate: [BasicAuhtenticationGuard, RouterLanguageGaurdGuard]
  },
  {
    path: ':languageId/verify/:partipationInfo',
    children: [
      {
        path: '**',
        children: [],
        canActivate: [BasicAuhtenticationGuard, RouterLanguageGaurdGuard]
      }
    ],
    canActivate: [BasicAuhtenticationGuard, RouterLanguageGaurdGuard]
  },
  { 
    path: 'stories',
    loadChildren: () => import('../app/modules/stories/stories.module').then(m => m.StoriesModule),
    canActivate: [BasicAuhtenticationGuard, RouterLanguageGaurdGuard, ProdEnabledGuard, ToggleGuard]
  },
  {
    path: ':languageId/stories',
    loadChildren: () => import('../app/modules/stories/stories.module').then(m => m.StoriesModule),
    canActivate: [BasicAuhtenticationGuard, ProdEnabledGuard, ToggleGuard ],
    data: {page: 'enableMyStories'}
  },
  {
    path: 'explore',
    loadChildren: () => import('../app/modules/explore/explore.module').then(explore => explore.ExploreModule),
    canActivate: [BasicAuhtenticationGuard, RouterLanguageGaurdGuard]
  },
  {
    path: ':languageId/explore',
    canActivate: [BasicAuhtenticationGuard],
    loadChildren: () => import('../app/modules/explore/explore.module').then(explore => explore.ExploreModule),
  },  
  {    
    path: ':languageId/privacy',
    component: PrivacyPageComponent,
    canActivate: [BasicAuhtenticationGuard]
  },
  {
    path: ':languageId/provider',
    component: ProviderPageComponent,
    canActivate: [BasicAuhtenticationGuard]
  },
  {
    path: ':languageId/legal-notice',
    component: LegalPageComponent,
    canActivate: [BasicAuhtenticationGuard]
  },
  {
    path: ':languageId/terms-conditions',
    component: LegalPageComponent,
    canActivate: [BasicAuhtenticationGuard]
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent,
    canActivate: [BasicAuhtenticationGuard]
  },
  {

      path: 'home',

      component: MainPageComponent,
      canActivate: [BasicAuhtenticationGuard]

    },
    {
      path: ':languageId/404',
      component: NoRouteComponent,
      canActivate: [BasicAuhtenticationGuard]
    },
  
  { path: '**', component: NoRouteComponent, canActivate: [BasicAuhtenticationGuard] },
    
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'  
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BasicAuth } from 'src/app/interfaces/basic-auth.interface';
import { CommonModalServiceService } from 'src/app/services/common-modal-service.service';
import { GenericLoginService } from 'src/app/services/generic-login.service';

@Component({
  selector: 'app-generic-login-page',
  templateUrl: './generic-login-page.component.html',
  styleUrls: ['./generic-login-page.component.scss']
})
export class GenericLoginPageComponent implements OnInit {

  username = '';
  password = '';
  submitted = false;
  submittedUsername = '';
  submittedPassword = '';
  popupType: string = 'small-popup';
  isInputError = false;

  constructor(
    private _commomModalService: CommonModalServiceService,
    private genericLoginService:GenericLoginService,
   ) {}

  public ngOnInit(): void {
    this._commomModalService._modalHeaderTitle.next('CP_LM_LANDINGPAGE_LOGIN')
  }

  onSubmit(form: NgForm) {
    this.isInputError = false;
    if (form.valid) {
      this.submitted = true;
      this.submittedUsername = this.username;
      this.submittedPassword = this.password;
      // You can send the form data to a service or perform other actions here.
      //console.log(form.value); // Log form data to console
      //reset the form after submit
      //form.resetForm();

      //api to call and get the stauts goes here.....
      this.genericLoginService.basicAuthLogin(this.submittedUsername, this.submittedPassword).subscribe(
        (data: BasicAuth) => {
          if (data.success) {
            this.genericLoginService.triggerLogin();
          } else {
            this.isInputError = true;
            this.genericLoginService.declineLogin();
          }
        }, 
        (error: Error) => {
          this.isInputError = true;
          this.genericLoginService.declineLogin();
        }
      )
    }
  }
}

import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
// import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
// import { TranslationModule } from './modules/translation.module';
// import { CommonModalComponent } from './components/common-modal/common-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { CommonModalComponent } from './components/common-modal/common-modal.component';
import { CommonModule } from '@angular/common';
import { TranslationModule } from './modules/translation.module';
import { NoRouteComponent } from './modules/shared/components/no-route/no-route.component';
import { SpinnerComponentComponent } from './modules/shared/components/spinner-component/spinner-component.component';
import { LegalPageComponent } from './modules/shared/pages/legal-page/legal-page.component';
import { PrivacyPageComponent } from './modules/shared/pages/privacy-page/privacy-page.component';
import { ProviderPageComponent } from './modules/shared/pages/provider-page/provider-page.component';
import { SafePipe } from './pipes/safe.pipe';
import { CustomToastComponent } from './modules/shared/components/toast/custom-toast.component';
import { GenericLoginPageComponent } from './components/generic-login-page/generic-login-page.component';
@NgModule({
    declarations: [EditProfileComponent,
    CommonModalComponent,
    NoRouteComponent,
    SpinnerComponentComponent,
    PrivacyPageComponent,
    ProviderPageComponent,
    LegalPageComponent,
    SafePipe,
    CustomToastComponent,
    GenericLoginPageComponent,
    ],
    imports: [CommonModule,  TranslationModule, ReactiveFormsModule, FormsModule],
    exports: [EditProfileComponent, CommonModalComponent, NoRouteComponent, SpinnerComponentComponent, SafePipe, CustomToastComponent, TranslationModule, GenericLoginPageComponent]
  })
  export class SharedModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout-handler',
  templateUrl: './logout-handler.component.html',
  styleUrls: ['./logout-handler.component.scss']
})
export class LogoutHandlerComponent implements OnInit {
  isProdEnvironment: boolean = true;

  constructor(private _awsauthService: AwsAuthService, private translationService:TranslationService, private _router : Router) { }
  isCiamClicked: any;
  reLoginForToken: any;
  signedIn = false;
  isCiamLinkingInProgress = false;

  
  ngOnInit(): void {
    this.isCiamClicked = sessionStorage.getItem('ciamClicked');
    let featureFlag = this.translationService.featureFlags;
    this.isProdEnvironment = featureFlag?.general.tempProdFlag;
    this.handleDifferentIdLogin();
  }

  /**
   * @description This is triggered when the user is in /logout route
   Generally happens when we click on Connect with Other Mercedes Me ID link
   Handles navigation. If user clicks on Connect with Other Mercedes Me ID from any page
   and session storage has this variable set we navigate user to CIAM
   */
  public handleDifferentIdLogin(): void {
    this.isCiamLinkingInProgress = sessionStorage.getItem('partcipationDetails') ? true : false;
    if (this.isCiamClicked) {
      this._awsauthService.manageLoginResponse();
    } else {
      const language = localStorage.getItem('language') ?? 'en-GB';
      this._router.navigateByUrl('/' + language + '/home');
    }
  }

}